import React from 'react';
import Entry from "./enter";

const EntryFI = () => {

    const initialStateOverride = {
        showDraw: false,
        showFast: true,
        showInstant: true,
    }

    return (
        <Entry
            { ... { initialStateOverride } }
        />
    );
};


export default EntryFI;

